<template>
      <div>
            <b-card>
                  <b-row>
                        <b-col sm="9" class="d-flex mb-1 mt-1">
                              <h6>Tipo Acreencia</h6>
                        </b-col>
                        <b-col sm="3" class="d-flex mb-1 mt-1">
                              <h6>Codigo Acreencia</h6>
                        </b-col>
                  </b-row>
                  <b-row>
                        <b-col sm="9" class="d-flex">
                              <feather-icon icon="UserPlusIcon" size="30" class="mt-1" />
                              <h6 class="mt-1 ml-3">{{ name }}</h6>
                        </b-col>
                        <b-col sm="3" class="d-flex">
                              <h6 class="mt-1 ml-3">{{ type }}</h6>
                        </b-col>
                  </b-row>
            </b-card>
            <b-card>
                  <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                              <div class="d-flex align-items-center">
                                    <label class="mr-1">Search</label>
                                    <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                          class="d-inline-block" />
                              </div>
                        </b-form-group>
                  </div>
                  <vue-good-table :columns="columns" :rows="rows" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                  }" :pagination-options="{
      enabled: true,
      perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">
                              <div v-if="props.column.field === 'action'" class="text-nowrap">
                                    <RouterLink :to="{ path: `/auditory/credits/${props.row.reclamation}/${assignmentBatchId}` }">
                                          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                                                class="btn-icon rounded-circle">
                                                <feather-icon icon="EyeIcon" />
                                          </b-button>
                                    </RouterLink>
                              </div>
                        </template>
                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                              <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                          <span class="text-nowrap">
                                                Showing 1 to
                                          </span>
                                          <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                          <span class="text-nowrap "> of {{ props.total }} entries </span>
                                    </div>

                                    <div>
                                          <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                                first-number last-number align="right" prev-class="prev-item"
                                                next-class="next-item" class="mt-1 mb-0"
                                                @input="(value) => props.pageChanged({ currentPage: value })">
                                                <template #prev-text>
                                                      <feather-icon icon="ChevronLeftIcon" size="18" />
                                                </template>
                                                <template #next-text>
                                                      <feather-icon icon="ChevronRightIcon" size="18" />
                                                </template>
                                          </b-pagination>
                                    </div>
                              </div>
                        </template>
                  </vue-good-table>
            </b-card>
      </div>
</template>
        
<script>
import {
      BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
export default {
      components: {
            VueGoodTable,
            BAvatar,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BDropdown,
            BDropdownItem,
            BCard,
            BButton,
            BRow,
            BCol
      },
      data() {
            return {
                  rows: [],
                  pageLength: 3,
                  dir: false,
                  columns: [
                        {
                              label: 'Número Acreencia',
                              field: 'reclamation',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Name',
                              },
                        },
                        {
                              label: 'Tipo Documento',
                              field: 'type_documment',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Email',
                              },
                        },
                        {
                              label: 'Documento',
                              field: 'document',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Razón Social',
                              field: 'name',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Cantidad Creditos',
                              field: 'quantity',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Valor',
                              field: 'value',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Creditos Pendientes',
                              field: 'quantity_pendding',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Valor Pendiente',
                              field: 'pendding_value',
                              filterOptions: {
                                    enabled: true,
                                    placeholder: 'Search Date',
                              },
                        },
                        {
                              label: 'Action',
                              field: 'action',
                        },
                  ],
                  searchTerm: '',
                  typeCreditId: null,
                  name: null,
                  type: null,
                  assignmentBatchId: this.$route.params && this.$route.params.assignmentBatchId,
                  typeCredit: this.$route.params && this.$route.params.typeCredit,
            }
      },
      methods: {
            fetchTypeOfCredit() {
                  this.$http.get('/assignment/credit/' + this.typeCredit +  '/assignment/' +  this.assignmentBatchId).then((response) => {
                        this.rows = response.data.credits
                        this.totalRows = response.data.total
                        this.typeCreditId = response.data.typeCredit.id
                        this.type = response.data.typeCredit.type
                        this.name = response.data.typeCredit.name
                  })
            }
      },
      created() {
            this.fetchTypeOfCredit()
      },
      directives: {
            Ripple,
      },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>